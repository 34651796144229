import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "HomePage2",
        component: () => import(/* webpackChunkName: "HomePage" */ "../views/HomePage.vue")
    },
    {
        path: "/second",
        name: "DashBoard",
        component: () => import(/* webpackChunkName: "DashBoard" */ "../views/DashBoard.vue")
    },
    {
        path: "/2fa",
        name: "Totp",
        component: () => import(/* webpackChunkName: "Totp" */ "../views/Totp.vue")
    },
    {
        path: "/third",
        name: "Payment",
        component: () => import(/* webpackChunkName: "Payment" */ "../views/Payment.vue")
    },
    {
        path: "/error",
        name: "Error",
        component: () => import(/* webpackChunkName: "Error" */ "../views/Error.vue")
    },
    {
        path: "/resetpassword",
        name: "Reset Password",
        component: () => import(/* webpackChunkName: "Error" */ "../views/Reset.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
