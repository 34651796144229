<template>
    <router-view> </router-view>
    <notifications position="top right" class="mynot" />
</template>

<style>
#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    /* overflow: hidden; */
}

#nav {
    padding: 1rem;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.notification-title {
    font-size: 24px !important;
}

.notification-content {
    font-size: 16px !important;
}
</style>
